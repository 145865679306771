export const i18nKeys = {
  header: {
    input: 'header.input',
    search: 'header.search',
    profile: 'header.profile',
    logOut: 'header.logOut',
  },
  sideMenu: {
    about: 'sideMenu.about',
    newQuote: 'sideMenu.newQuote',
    home: 'sideMenu.home',
  },
  quote: {
    customer: 'quote.customer',
    products: 'quote.products',
    productNumber: 'quote.productNumber',
    details: 'quote.details',
    optional: 'quote.optional',
    statement: 'quote.statement',
    documents: 'quote.documents',
    review: 'quote.review',
    approval: 'quote.approval',
    type: 'quote.type',
    number: 'quote.number',
    address: 'quote.address',
    city: 'quote.city',
    st: 'quote.st',
    zip: 'quote.zip',
    noContact: 'quote.noContact',
    contact: 'quote.contact',
    officePhone: 'quote.officePhone',
    phoneExt: 'quote.phoneExt',
    title: 'quote.title',
    cellPhone: 'quote.cellPhone',
    fax: 'quote.fax',
    state: 'quote.state',
    additionalInformation: 'quote.additionalInformation',
    quoteNumber: 'quote.quoteNumber',
    createdDate: 'quote.createdDate',
    preparedDate: 'quote.preparedDate',
    contactName: 'quote.contactName',
    status: 'quote.status',
    total: 'quote.total',
    description: 'quote.description',
    preview: 'quote.preview',
    taxExempt: 'quote.taxExempt',
    workOfImprov: 'quote.workOfImprov',
    prevWage: 'quote.prevWage',
    jobsite: 'quote.jobsite',
    jobName: 'quote.jobName',
    siteContact: 'quote.siteContact',
    siteNumber: 'quote.siteNumber',
    sitePhone: 'quote.sitePhone',
    PONumber: 'quote.PONumber',
    ext: 'quote.ext',
    fillWithContact: 'quote.fillWithContact',
    detailsTab: {
      projectDescription: {
        useJobsiteNameButton: 'quote.detailsTab.projectDescription.useJobsiteNameButton',
      },
    },
    titles: {
      lastQuotes: 'quote.titles.lastQuotes',
      jobSiteInfo: 'quote.titles.jobSiteInfo',
      contact: 'quote.titles.contact',
      marketSegment: 'quote.titles.marketSegment',
      marketSector: 'quote.titles.marketSector',
      account: 'quote.titles.account',
      billRequirements: 'quote.titles.billRequirements',
      oilAndGas: 'quote.titles.oilAndGas',
      purchaseOrder: 'quote.titles.purchaseOrder',
    },
    rental: 'quote.rental',
    basicSearch: 'quote.basicSearch',
    addToQuote: 'quote.addToQuote',
    addAsOptional: 'quote.addAsOptional',
    clearSearch: 'quote.clearSearch',
    quantity: 'quote.quantity',
    unit: 'quote.unit',
    productNum: 'quote.productNum',
    code: 'quote.code',
    categoryHeader: 'quote.categoryHeader',
    additionalInfoOrCommentsHeader: 'quote.additionalInfoOrCommentsHeader',
    rateTypeHeader: 'quote.rateTypeHeader',
    day: 'quote.day',
    week: 'quote.week',
    cycle: 'quote.cycle',
    period: 'quote.period',
    duration: 'quote.duration',
    regularDurationHeader: 'quote.regularDurationHeader',
    overtimeDurationHeader: 'quote.overtimeDurationHeader',
    doubleTimeDurationHeader: 'quote.doubleTimeDurationHeader',
    rateHeader: 'quote.rateHeader',
    item: 'quote.item',
    extension: 'quote.extension',
    ratePercentage: 'quote.ratePercentage',
    rppFee: 'quote.rppFee',
    rv: 'quote.rv',
    regularRate: 'quote.regularRate',
    overtimeRate: 'quote.overtimeRate',
    actions: 'quote.actions',
    originalCost: 'quote.originalCost',
    margin: 'quote.margin',
    price: 'quote.price',
    priceEach: 'quote.priceEach',
    fileName: 'quote.fileName',
    descriptions: 'quote.descriptions',
    format: 'quote.format',
    size: 'quote.size',
    uploadedDate: 'quote.uploadedDate',
    uploadedBy: 'quote.uploadedBy',
    lastAccessedBy: 'quote.lastAccessedBy',
    lastAccessedDate: 'quote.lastAccessedDate',
    creditRating: 'quote.creditRating',
    creditLimit: 'quote.creditLimit',
    customerSegment: 'quote.customerSegment',
    currentBalance: 'quote.currentBalance',
    customerSince: 'quote.customerSince',
    PORequired: 'quote.PORequired',
    envRecFee: 'quote.envRecFee',
    airQualityFee: 'quote.airQualityFee',
    contractRate: 'quote.contractRate',
    nationalCust: 'quote.nationalCust',
    cusNumOrName: 'quote.cusNumOrName',
    accountClosed: 'quote.accountClosed',
    valueRangeErrors: {
      valueTooSmall: 'quote.valueRangeErrors.valueTooSmall',
      valueTooLarge: 'quote.valueRangeErrors.valueTooLarge',
    },
    productSearch: {
      tooManyItemsSelected: 'quote.productSearch.tooManyItemsSelected',
      addServicesTo: 'quote.productSearch.addServicesTo',
    },
    okButton: 'quote.okButton',
    services: {
      errorGettingPhaseServices: 'quote.services.errorGettingPhaseServices',
      selectedServicesNotSupportedByTargetPackage: 'quote.services.selectedServicesNotSupportedByTargetPackage',
      servicesCopied: 'quote.services.servicesCopied',
      copyTo: 'quote.services.copyTo',
      rowMoveItem: 'quote.services.rowMoveItem',
      packageMoveItems: 'quote.services.packageMoveItems',
      movePackage: 'quote.services.movePackage',
      packageMoved: 'quote.services.packageMoved',
      movePackageUp: 'quote.services.movePackageUp',
      movePackageDown: 'quote.services.movePackageDown',
      deletePackage: 'quote.services.deletePackage',
      confirmDeletePackage: {
        title: 'quote.services.confirmDeletePackage.title',
        message: 'quote.services.confirmDeletePackage.message',
      },
    },
    yes: 'quote.yes',
    no: 'quote.no',
    quoteStatusLabel: 'quote.quoteStatusLabel',
  },
  details: {
    sections: {
      projectDesc: 'details.sections.projectDesc',
      AdditionalInformation: 'details.sections.AdditionalInformation',
      applicationInfo: 'details.sections.applicationInfo',
      internalNotes: 'details.sections.internalNotes',
      jobAndComments: 'details.sections.jobAndComments',
    },
    project: {
      name: 'details.project.name',
      quotes: 'details.project.quotes',
      duration: 'details.project.duration',
      description: 'details.project.description',
    },
    AdditionalInformation: {
      datePrepared: 'details.AdditionalInformation.datePrepared',
      estimatedDeliveryDateLabel: 'details.AdditionalInformation.estimatedDeliveryDateLabel',
      estimatedCompletionDateLabel: 'details.AdditionalInformation.estimatedCompletionDateLabel',
      estimatedCompletionMinDateMessage: 'details.AdditionalInformation.estimatedCompletionMinDateMessage',
    },
    applicationInfo: {
      material: 'details.applicationInfo.material',
      flow: 'details.applicationInfo.flow',
      suctionLift: 'details.applicationInfo.suctionLift',
      frictionLoss: 'details.applicationInfo.frictionLoss',
      staticHead: 'details.applicationInfo.staticHead',
    },
    notes: 'details.notes',
    engineeringJob: {
      notes: 'details.engineeringJob.notes',
      number: 'details.engineeringJob.number',
    },
  },
  aboutModal: {
    salesPro: 'aboutModal.salesPro',
    version: 'aboutModal.version',
  },
  app: {
    errorGettingCriticalData: 'app.errorGettingCriticalData',
    errorFetchingUsers: 'app.errorFetchingUsers',
    noOptions: 'app.noOptions',
  },
  toolbar: {
    reports: 'toolbar.reports',
    reportsMenu: {
      productDetails: 'toolbar.reportsMenu.productDetails',
      partsList: 'toolbar.reportsMenu.partsList',
      services: 'toolbar.reportsMenu.services',
      print: 'toolbar.reportsMenu.print',
      saveCsv: 'toolbar.reportsMenu.saveCsv',
    },
    tqhUnsavedChangesTooltip: 'toolbar.tqhUnsavedChangesTooltip',
    prevailingWage: 'toolbar.prevailingWage',
  },
  tabBar: {
    customerNameLabel: 'tabBar.customerNameLabel',
    quoteNumberLabel: 'tabBar.quoteNumberLabel',
    clonedFrom: 'tabBar.clonedFrom',
  },
  common: {
    deleteWithConfirmationLabel: 'common.deleteWithConfirmationLabel',
    clear: 'common.clear',
    cancel: 'common.cancel',
    ok: 'common.ok',
    invalidDateMessage: 'common.invalidDateMessage',
    confirm: 'common.confirm',
  },
  productsTab: {
    invalidDescription: 'productsTab.invalidDescription',
    searchTitle: 'productsTab.searchTitle',
    errorLoadingCategories: 'productsTab.errorLoadingCategories',
    categorySearchPlaceholder: 'productsTab.categorySearchPlaceholder',
    productNotFoundTitle: 'productsTab.productNotFoundTitle',
    productNotFoundMessage: 'productsTab.productNotFoundMessage',
    totalsSummary: {
      rental: 'productsTab.totalsSummary.rental',
      sales: 'productsTab.totalsSummary.sales',
      services: 'productsTab.totalsSummary.services',
      erFeeTotal: 'productsTab.totalsSummary.erFeeTotal',
      aqFeeTotal: 'productsTab.totalsSummary.aqFeeTotal',
      fscFeeTotal: 'productsTab.totalsSummary.fscFeeTotal',
      grandTotal: 'productsTab.totalsSummary.grandTotal',
    },
    deleteRows: {
      confirmDialog: {
        title: 'productsTab.deleteRows.confirmDialog.title',
        message: 'productsTab.deleteRows.confirmDialog.message',
      },
    },
    csvImport: {
      actionLabel: 'productsTab.csvImport.actionLabel',
      fileTooLarge: 'productsTab.csvImport.fileTooLarge',
      noHeader: 'productsTab.csvImport.noHeader',
      noCode: 'productsTab.csvImport.noCode',
      noRows: 'productsTab.csvImport.noRows',
      genericError: 'productsTab.csvImport.genericError',
    },
  },
  reviewTab: {
    rentalGridTitle: 'reviewTab.rentalGridTitle',
    rentalGridSubTotal: 'reviewTab.rentalGridSubTotal',
    rentalTotalsTitle: 'reviewTab.rentalTotalsTitle',
    numberOfRentalProducts: 'reviewTab.numberOfRentalProducts',
    quotedTotal: 'reviewTab.quotedTotal',
    listTotal: 'reviewTab.listTotal',
    dollarDifference: 'reviewTab.dollarDifference',
    percentOfRate: 'reviewTab.percentOfRate',
    rppEligibleProductsTotal: 'reviewTab.rppEligibleProductsTotal',
    rppFeeTotal: 'reviewTab.rppFeeTotal',
    salesGridTitle: 'reviewTab.salesGridTitle',
    salesGridSubTotal: 'reviewTab.salesGridSubTotal',
    salesTotalsTitle: 'reviewTab.salesTotalsTitle',
    numberOfSalesProducts: 'reviewTab.numberOfSalesProducts',
    totalPriceEach: 'reviewTab.totalPriceEach',
    totalOriginalCost: 'reviewTab.totalOriginalCost',
    totalMarginDollars: 'reviewTab.totalMarginDollars',
    totalMarginPercent: 'reviewTab.totalMarginPercent',
    services: 'reviewTab.services',
    servicesGridSubTotal: 'reviewTab.servicesGridSubTotal',
    servicesTotalsTitle: 'reviewTab.servicesTotalsTitle',
    numberOfServicePackages: 'reviewTab.numberOfServicePackages',
    deliveryServicesTotal: 'reviewTab.deliveryServicesTotal',
    installationServicesTotal: 'reviewTab.installationServicesTotal',
    removalServicesTotal: 'reviewTab.removalServicesTotal',
    pickupServicesTotal: 'reviewTab.pickupServicesTotal',
    servicesServicesTotal: 'reviewTab.servicesServicesTotal',
    rentalSubTotal: 'reviewTab.rentalSubTotal',
    salesSubTotal: 'reviewTab.salesSubTotal',
    servicesSubTotal: 'reviewTab.servicesSubTotal',
    environmentalRecoveryFees: 'reviewTab.environmentalRecoveryFees',
    airQualityFees: 'reviewTab.airQualityFees',
    fuelSurcharges: 'reviewTab.fuelSurcharges',
    estimatedGrandTotal: 'reviewTab.estimatedGrandTotal',
    optionalItemsTitle: 'reviewTab.optionalItemsTitle',
    optionalItemsTabs: 'reviewTab.optionalItemsTabs',
    optionalRentalTab: 'reviewTab.optionalRentalTab',
    optionalSalesTab: 'reviewTab.optionalSalesTab',
    optionalServicesTab: 'reviewTab.optionalServicesTab',
    optionalItemsSubTotal: 'reviewTab.optionalItemsSubTotal',
    subTotals: 'reviewTab.subTotals',
    submissionModal: {
      dateRequiredMessage: 'reviewTab.submissionModal.dateRequiredMessage',
      dateInThePastMessage: 'reviewTab.submissionModal.dateInThePastMessage',
      invalidTimeMessage: 'reviewTab.submissionModal.invalidTimeMessage',
      defaultTimeMessage: 'reviewTab.submissionModal.defaultTimeMessage',
      quoteWarningsHeader: 'reviewTab.submissionModal.quoteWarningsHeader',
      changeOrderWarningsHeader: 'reviewTab.submissionModal.changeOrderWarningsHeader',
      warningMessages: {
        grandTotal: 'reviewTab.submissionModal.warningMessages.grandTotal',
        creditLimit: 'reviewTab.submissionModal.warningMessages.creditLimit',
        currentBalance: 'reviewTab.submissionModal.warningMessages.currentBalance',
      },
    },
  },
  statementTab: {
    errorLoadingStatement: 'statementTab.errorLoadingStatement',
    errorLoadingTemplates: 'statementTab.errorLoadingTemplates',
    errorLoadingTemplate: 'statementTab.errorLoadingTemplate',
    errorSavingStatement: 'statementTab.errorSavingStatement',
    statementTooLarge: 'statementTab.statementTooLarge',
    fatalLoadError: 'statementTab.fatalLoadError',
    editorPlaceholder: 'statementTab.editorPlaceholder',
    templatesListLabel: 'statementTab.templatesListLabel',
    resetButton: 'statementTab.resetButton',
    confirmResetTitle: 'statementTab.confirmResetTitle',
    confirmResetMessage: 'statementTab.confirmResetMessage',
    confirmTemplateChangeTitle: 'statementTab.confirmTemplateChangeTitle',
    confirmTemplateChangeMessage: 'statementTab.confirmTemplateChangeMessage',
    sectionsHeader: 'statementTab.sectionsHeader',
  },
  documentsTab: {
    headerText: 'documentsTab.headerText',
    uploadButtonText: 'documentsTab.uploadButtonText',
    noDocuments: 'documentsTab.noDocuments',
    errorFetchingDocumentList: 'documentsTab.errorFetchingDocumentList',
    documentUpdateErrorMessage: 'documentsTab.documentUpdateErrorMessage',
    deleteDocumentConfirmationMessage: 'documentsTab.deleteDocumentConfirmationMessage',
    errorDeletingDocument: 'documentsTab.errorDeletingDocument',
    api: {
      errorAddingDocument: 'documentsTab.api.errorAddingDocument',
      errorUploadingFile: 'documentsTab.api.errorUploadingFile',
    },
    tableHeaders: {
      fileName: 'documentsTab.tableHeaders.fileName',
      fileSize: 'documentsTab.tableHeaders.fileSize',
      description: 'documentsTab.tableHeaders.description',
      documentType: 'documentsTab.tableHeaders.documentType',
      fileFormat: 'documentsTab.tableHeaders.fileFormat',
      uploadedDate: 'documentsTab.tableHeaders.uploadedDate',
      uploadedBy: 'documentsTab.tableHeaders.uploadedBy',
      lastAccessedBy: 'documentsTab.tableHeaders.lastAccessedBy',
      lastAccessedDate: 'documentsTab.tableHeaders.lastAccessedDate',
    },
    uploadDialog: {
      title: 'documentsTab.uploadDialog.title',
      browseButton: 'documentsTab.uploadDialog.browseButton',
      chooseFilesHeader: 'documentsTab.uploadDialog.chooseFilesHeader',
      noFilesAddedForUpload: 'documentsTab.uploadDialog.noFilesAddedForUpload',
      uploadButton: 'documentsTab.uploadDialog.uploadButton',
      closeButton: 'documentsTab.uploadDialog.closeButton',
      unsupportedFileTypeMessage: 'documentsTab.uploadDialog.unsupportedFileTypeMessage',
      fileTooLargeMessage: 'documentsTab.uploadDialog.fileTooLargeMessage',
      fileNameAlreadyAddedMessage: 'documentsTab.uploadDialog.fileNameAlreadyAddedMessage',
      fileNameAlreadyExistsMessage: 'documentsTab.uploadDialog.fileNameAlreadyExistsMessage',
      extraFilesNotAddedMessage: 'documentsTab.uploadDialog.extraFilesNotAddedMessage',
      genericFilesNotAddedMessage: 'documentsTab.uploadDialog.genericFilesNotAddedMessage',
      missingDescriptionMessage: 'documentsTab.uploadDialog.missingDescriptionMessage',
      missingTypeMessage: 'documentsTab.uploadDialog.missingTypeMessage',
    },
    uploadProgressDialog: {
      title: 'documentsTab.uploadProgressDialog.title',
      closeButton: 'documentsTab.uploadProgressDialog.closeButton',
    },
  },
  alertDialogTitle: 'alertDialogTitle',
  changeOrderTab: {
    headerText: 'changeOrderTab.headerText',
    createButtonText: 'changeOrderTab.createButtonText',
    noChangeOrders: 'changeOrderTab.noChangeOrders',
    errorGettingChangeOrdersMessage: 'changeOrderTab.errorGettingChangeOrdersMessage',
    errorCreatingChangeOrderMessage: 'changeOrderTab.errorCreatingChangeOrderMessage',
    tableHeaders: {
      changeOrder: 'changeOrderTab.tableHeaders.changeOrder',
      preparedBy: 'changeOrderTab.tableHeaders.preparedBy',
      createdDate: 'changeOrderTab.tableHeaders.createdDate',
      updateDate: 'changeOrderTab.tableHeaders.updateDate',
      total: 'changeOrderTab.tableHeaders.total',
      scopeChanges: 'changeOrderTab.tableHeaders.scopeChanges',
      status: 'changeOrderTab.tableHeaders.status',
      actions: 'changeOrderTab.tableHeaders.actions',
    },
  },
  changeOrder: {
    errorFetchingGroupsMessage: 'changeOrder.errorFetchingGroupsMessage',
    groupsSavedMessage: 'changeOrder.groupsSavedMessage',
    errorSavingGroupsMessage: 'changeOrder.errorSavingGroupsMessage',
    quoteStatusLabel: 'changeOrder.quoteStatusLabel',
  },
  scopeChangesTab: {
    scopeChangesSectionTitle: 'scopeChangesTab.scopeChangesSectionTitle',
    scopeChangesFieldPlaceholder: 'scopeChangesTab.scopeChangesFieldPlaceholder',
    projectDescriptionSectionTitle: 'scopeChangesTab.projectDescriptionSectionTitle',
    datesSectionTitle: 'scopeChangesTab.datesSectionTitle',
    quoteEstimatedDeliveryDateLabel: 'scopeChangesTab.quoteEstimatedDeliveryDateLabel',
    quoteEstimatedCompletionDateLabel: 'scopeChangesTab.quoteEstimatedCompletionDateLabel',
    preparedDateLabel: 'scopeChangesTab.preparedDateLabel',
    estimatedDeliveryDateLabel: 'scopeChangesTab.estimatedDeliveryDateLabel',
    estimatedCompletionDateLabel: 'scopeChangesTab.estimatedCompletionDateLabel',
    estimatedCompletionMinDateMessage: 'scopeChangesTab.estimatedCompletionMinDateMessage',
  },
  cloneDialog: {
    title: 'cloneDialog.title',
    cloneAccount: 'cloneDialog.cloneAccount',
    cloneJobsite: 'cloneDialog.cloneJobsite',
    cloneRates: 'cloneDialog.cloneRates',
    cloneStatement: 'cloneDialog.cloneStatement',
    cloneRelatedUsers: 'cloneDialog.cloneRelatedUsers',
    clone: 'cloneDialog.clone',
    cancel: 'cloneDialog.cancel',
  },
  opsPack: {
    generate: 'opsPack.generate',
    regenerate: 'opsPack.regenerate',
    regenerateWarningTitle: 'opsPack.regenerateWarningTitle',
    regenerateWarningMessage: 'opsPack.regenerateWarningMessage',
    view: 'opsPack.view',
    getSummaryError: 'opsPack.getSummaryError',
    generateSuccess: 'opsPack.generateSuccess',
    generateError: 'opsPack.generateError',
    reconnectError: 'opsPack.reconnectError',
    branchFolderMissingError: 'opsPack.branchFolderMissingError',
  },
  quoteOwners: {
    errorAssigningQuote: 'quoteOwners.errorAssigningQuote',
    preparedBy: 'quoteOwners.preparedBy',
    assignedTo: 'quoteOwners.assignedTo',
  },
  approvalRequest: {
    date: 'approvalRequest.date',
    time: 'approvalRequest.time',
    urgent: 'approvalRequest.urgent',
    text: 'approvalRequest.text',
  },
  approval: {
    requestInformationHeader: 'approval.requestInformationHeader',
    approvalHeader: 'approval.approvalHeader',
    outcomeHeader: 'approval.outcomeHeader',
    tierNames: {
      BRANCH: 'approval.tierNames.BRANCH',
      REGION: 'approval.tierNames.REGION',
      COMPANY: 'approval.tierNames.COMPANY',
    },
    shortTierNames: {
      BRANCH: 'approval.shortTierNames.BRANCH',
      REGION: 'approval.shortTierNames.REGION',
      COMPANY: 'approval.shortTierNames.COMPANY',
    },
    approve: 'approval.approve',
    approveDialog: {
      title: 'approval.approveDialog.title',
      message: 'approval.approveDialog.message',
      confirmButton: 'approval.approveDialog.confirmButton',
      cancelButton: 'approval.approveDialog.cancelButton',
      successMessage: 'approval.approveDialog.successMessage',
      errorMessage: 'approval.approveDialog.errorMessage',
    },
    reject: 'approval.reject',
    rejectionReason: 'approval.rejectionReason',
    rejectDialog: {
      title: 'approval.rejectDialog.title',
      reason: 'approval.rejectDialog.reason',
      rejectButton: 'approval.rejectDialog.rejectButton',
      cancelButton: 'approval.rejectDialog.cancelButton',
      successMessage: 'approval.rejectDialog.successMessage',
      errorMessage: 'approval.rejectDialog.errorMessage',
    },
    requiresHigherLimit: 'approval.requiresHigherLimit',
    approvedBy: 'approval.approvedBy',
    rejectedBy: 'approval.rejectedBy',
    reset: 'approval.reset',
    markWon: 'approval.markWon',
    markLost: 'approval.markLost',
    markedWonBy: 'approval.markedWonBy',
    markedLostBy: 'approval.markedLostBy',
    lostReason: 'approval.lostReason',
    lostComments: 'approval.lostComments',
    reopen: 'approval.reopen',
    wonDialog: {
      title: 'approval.wonDialog.title',
      message: 'approval.wonDialog.message',
      confirmButton: 'approval.wonDialog.confirmButton',
      cancelButton: 'approval.wonDialog.cancelButton',
      successMessage: 'approval.wonDialog.successMessage',
      errorMessage: 'approval.wonDialog.errorMessage',
    },
    prospectWonDialog: {
      title: 'approval.prospectWonDialog.title',
      message: 'approval.prospectWonDialog.message',
      okButton: 'approval.prospectWonDialog.okButton',
    },
    lostDialog: {
      reason: 'approval.lostDialog.reason',
      comments: 'approval.lostDialog.comments',
      cancelButton: 'approval.lostDialog.cancelButton',
      successMessage: 'approval.lostDialog.successMessage',
      errorMessage: 'approval.lostDialog.errorMessage',
    },
    reopenDialog: {
      title: 'approval.reopenDialog.title',
      message: 'approval.reopenDialog.message',
      confirmButton: 'approval.reopenDialog.confirmButton',
      cancelButton: 'approval.reopenDialog.cancelButton',
      successMessage: 'approval.reopenDialog.successMessage',
      errorMessage: 'approval.reopenDialog.errorMessage',
    },
    errorLoadingProfile: 'approval.errorLoadingProfile',
  },
  rateLockdown: {
    rateLockdown: 'rateLockdown.rateLockdown',
    unsavedChangesTitle: 'rateLockdown.unsavedChangesTitle',
    unsavedChangesMessage: 'rateLockdown.unsavedChangesMessage',
    dialog: {
      title: 'rateLockdown.dialog.title',
      closeButtonAriaLabel: 'rateLockdown.dialog.closeButtonAriaLabel',
      tabsAriaLabel: 'rateLockdown.dialog.tabsAriaLabel',
      rentalTab: 'rateLockdown.dialog.rentalTab',
      optionalRentalTab: 'rateLockdown.dialog.optionalRentalTab',
      rldRatesDescription: 'rateLockdown.dialog.rldRatesDescription',
      columnHeaders: {
        row: 'rateLockdown.dialog.columnHeaders.row',
        quantity: 'rateLockdown.dialog.columnHeaders.quantity',
        unit: 'rateLockdown.dialog.columnHeaders.unit',
        code: 'rateLockdown.dialog.columnHeaders.code',
        description: 'rateLockdown.dialog.columnHeaders.description',
        day: 'rateLockdown.dialog.columnHeaders.day',
        week: 'rateLockdown.dialog.columnHeaders.week',
        cycle: 'rateLockdown.dialog.columnHeaders.cycle',
        month: 'rateLockdown.dialog.columnHeaders.month',
        period: 'rateLockdown.dialog.columnHeaders.period',
        duration: 'rateLockdown.dialog.columnHeaders.duration',
      },
    },
  },
  quoteStatus: {
    unknown: 'quoteStatus.unknown',
    draft: 'quoteStatus.draft',
    awaitingApproval: 'quoteStatus.awaitingApproval',
    'awaitingApproval-BRANCH': 'quoteStatus.awaitingApproval-BRANCH',
    'awaitingApproval-BRANCH-REGION': 'quoteStatus.awaitingApproval-BRANCH-REGION',
    'awaitingApproval-BRANCH-COMPANY': 'quoteStatus.awaitingApproval-BRANCH-COMPANY',
    'awaitingApproval-BRANCH-REGION-COMPANY': 'quoteStatus.awaitingApproval-BRANCH-REGION-COMPANY',
    'awaitingApproval-REGION': 'quoteStatus.awaitingApproval-REGION',
    'awaitingApproval-REGION-COMPANY': 'quoteStatus.awaitingApproval-REGION-COMPANY',
    'awaitingApproval-COMPANY': 'quoteStatus.awaitingApproval-COMPANY',
    rejected: 'quoteStatus.rejected',
    'rejected-BRANCH': 'quoteStatus.rejected-BRANCH',
    'rejected-REGION': 'quoteStatus.rejected-REGION',
    'rejected-COMPANY': 'quoteStatus.rejected-COMPANY',
    approved: 'quoteStatus.approved',
    won: 'quoteStatus.won',
    lost: 'quoteStatus.lost',
  },
  quoteStatusFilters: {
    Draft: 'quoteStatusFilters.Draft',
    AwaitingBranchApproval: 'quoteStatusFilters.AwaitingBranchApproval',
    AwaitingRegionApproval: 'quoteStatusFilters.AwaitingRegionApproval',
    AwaitingCompanyApproval: 'quoteStatusFilters.AwaitingCompanyApproval',
    Approved: 'quoteStatusFilters.Approved',
    RejectedBranch: 'quoteStatusFilters.RejectedBranch',
    RejectedRegion: 'quoteStatusFilters.RejectedRegion',
    RejectedCompany: 'quoteStatusFilters.RejectedCompany',
    Won: 'quoteStatusFilters.Won',
    Lost: 'quoteStatusFilters.Lost',
  },
  deleteQuote: {
    cannotDeleteAlert: {
      title: 'deleteQuote.cannotDeleteAlert.title',
      reasons: {
        Migrated: 'deleteQuote.cannotDeleteAlert.reasons.Migrated',
        PreviouslyRejected: 'deleteQuote.cannotDeleteAlert.reasons.PreviouslyRejected',
        PreviouslyApproved: 'deleteQuote.cannotDeleteAlert.reasons.PreviouslyApproved',
        PreviouslyPartiallyApproved: 'deleteQuote.cannotDeleteAlert.reasons.PreviouslyPartiallyApproved',
        PreviouslyWon: 'deleteQuote.cannotDeleteAlert.reasons.PreviouslyWon',
        PreviouslyLost: 'deleteQuote.cannotDeleteAlert.reasons.PreviouslyLost',
        ApprovalPending: 'deleteQuote.cannotDeleteAlert.reasons.ApprovalPending',
        PartiallyApproved: 'deleteQuote.cannotDeleteAlert.reasons.PartiallyApproved',
        Rejected: 'deleteQuote.cannotDeleteAlert.reasons.Rejected',
        Approved: 'deleteQuote.cannotDeleteAlert.reasons.Approved',
        Lost: 'deleteQuote.cannotDeleteAlert.reasons.Lost',
        Won: 'deleteQuote.cannotDeleteAlert.reasons.Won',
      },
      okButton: 'deleteQuote.cannotDeleteAlert.okButton',
    },
    deleteDialog: {
      title: 'deleteQuote.deleteDialog.title',
      message: 'deleteQuote.deleteDialog.message',
      confirmButton: 'deleteQuote.deleteDialog.confirmButton',
      cancelButton: 'deleteQuote.deleteDialog.cancelButton',
      successMessage: 'deleteQuote.deleteDialog.successMessage',
      errorMessage: 'deleteQuote.deleteDialog.errorMessage',
    },
  },
  quotes: {
    universalSearchPlaceholder: 'quotes.universalSearchPlaceholder',
    reset: 'quotes.reset',
    grid: {
      headers: {
        code: 'quotes.grid.headers.code',
        assignedToName: 'quotes.grid.headers.assignedToName',
        preparedTimestamp: 'quotes.grid.headers.preparedTimestamp',
        deliveryTimestamp: 'quotes.grid.headers.deliveryTimestamp',
        accountName: 'quotes.grid.headers.accountName',
        accountCode: 'quotes.grid.headers.accountCode',
        jobName: 'quotes.grid.headers.jobName',
        projectDescription: 'quotes.grid.headers.projectDescription',
        contactName: 'quotes.grid.headers.contactName',
        statuses: 'quotes.grid.headers.statuses',
        rateLockdown: 'quotes.grid.headers.rateLockdown',
        urgent: 'quotes.grid.headers.urgent',
        total: 'quotes.grid.headers.total',
        actions: 'quotes.grid.headers.actions',
      },
      rateLockdownYes: 'quotes.grid.rateLockdownYes',
      rateLockdownNo: 'quotes.grid.rateLockdownNo',
      urgentYes: 'quotes.grid.urgentYes',
      urgentNo: 'quotes.grid.urgentNo',
    },
    searchError: 'quotes.searchError',
  },
  quoteType: 'quoteType',
  noFilter: 'noFilter',
  servicePhaseEstTotals: {
    DELIVERY: 'servicePhaseEstTotals.DELIVERY',
    INSTALL: 'servicePhaseEstTotals.INSTALL',
    REMOVE: 'servicePhaseEstTotals.REMOVE',
    PICKUP: 'servicePhaseEstTotals.PICKUP',
    SERVICE: 'servicePhaseEstTotals.SERVICE',
  },
  servicePhaseTotals: {
    DELIVERY: 'servicePhaseTotals.DELIVERY',
    INSTALL: 'servicePhaseTotals.INSTALL',
    REMOVE: 'servicePhaseTotals.REMOVE',
    PICKUP: 'servicePhaseTotals.PICKUP',
    SERVICE: 'servicePhaseTotals.SERVICE',
  },
  servicePhases: {
    DELIVERY: 'servicePhases.DELIVERY',
    INSTALL: 'servicePhases.INSTALL',
    REMOVE: 'servicePhases.REMOVE',
    PICKUP: 'servicePhases.PICKUP',
    SERVICE: 'servicePhases.SERVICE',
  },
  mobile: {
    quotePropertyValueYes: 'mobile.quotePropertyValueYes',
    quotePropertyValueNo: 'mobile.quotePropertyValueNo',
    notAvailable: 'mobile.notAvailable',
    branches: {
      navTitle: 'mobile.branches.navTitle',
      branchInfo: 'mobile.branches.branchInfo',
      quotesPendingApproval: 'mobile.branches.quotesPendingApproval',
      changeOrdersPendingApproval: 'mobile.branches.changeOrdersPendingApproval',
      fetchError: 'mobile.branches.fetchError',
    },
    quoteProps: {
      grandTotal: 'mobile.quoteProps.grandTotal',
      grandTotalWithOptional: 'mobile.quoteProps.grandTotalWithOptional',
      productsTotalWithOptional: 'mobile.quoteProps.productsTotalWithOptional',
      fsc: 'mobile.quoteProps.fsc',
      tqh: 'mobile.quoteProps.tqh',
      rental: 'mobile.quoteProps.rental',
      services: 'mobile.quoteProps.services',
      sales: 'mobile.quoteProps.sales',
      rateLockdown: 'mobile.quoteProps.rateLockdown',
      urgent: 'mobile.quoteProps.urgent',
      neededBy: 'mobile.quoteProps.neededBy',
      approvalNeededBy: 'mobile.quoteProps.approvalNeededBy',
      pendingApprovals: 'mobile.quoteProps.pendingApprovals',
      estimatedDeliveryDate: 'mobile.quoteProps.estimatedDeliveryDate',
      siteContact: 'mobile.quoteProps.siteContact',
      preparedBy: 'mobile.quoteProps.preparedBy',
      assignedTo: 'mobile.quoteProps.assignedTo',
      taxExempt: 'mobile.quoteProps.taxExempt',
      workOfImprovement: 'mobile.quoteProps.workOfImprovement',
      prevailingWage: 'mobile.quoteProps.prevailingWage',
      projectDescription: 'mobile.quoteProps.projectDescription',
      internalNotes: 'mobile.quoteProps.internalNotes',
      scopeChange: 'mobile.quoteProps.scopeChange',
      marketSector: 'mobile.quoteProps.marketSector',
    },
    branch: {
      navTitle: 'mobile.branch.navTitle',
      fetchError: 'mobile.branch.fetchError',
      searchPlaceholder: 'mobile.branch.searchPlaceholder',
    },
    quote: {
      QUOTE: {
        navTitle: 'mobile.quote.QUOTE.navTitle',
        fetchError: 'mobile.quote.QUOTE.fetchError',
        detailsTitle: 'mobile.quote.QUOTE.detailsTitle',
        notPendingApprovalAlert: {
          message: 'mobile.quote.QUOTE.notPendingApprovalAlert.message',
          ok: 'mobile.quote.QUOTE.notPendingApprovalAlert.ok',
        },
      },
      CHANGE_ORDER: {
        navTitle: 'mobile.quote.CHANGE_ORDER.navTitle',
        fetchError: 'mobile.quote.CHANGE_ORDER.fetchError',
        detailsTitle: 'mobile.quote.CHANGE_ORDER.detailsTitle',
        notPendingApprovalAlert: {
          message: 'mobile.quote.CHANGE_ORDER.notPendingApprovalAlert.message',
          ok: 'mobile.quote.CHANGE_ORDER.notPendingApprovalAlert.ok',
        },
      },
    },
    products: {
      subTotal: 'mobile.products.subTotal',
      withOptional: 'mobile.products.withOptional',
      optionalHeading: 'mobile.products.optionalHeading',
      rentalTableHeaders: {
        quantity: 'mobile.products.rentalTableHeaders.quantity',
        unit: 'mobile.products.rentalTableHeaders.unit',
        code: 'mobile.products.rentalTableHeaders.code',
        description: 'mobile.products.rentalTableHeaders.description',
        period: 'mobile.products.rentalTableHeaders.period',
        duration: 'mobile.products.rentalTableHeaders.duration',
        rppFee: 'mobile.products.rentalTableHeaders.rppFee',
        erfAqf: 'mobile.products.rentalTableHeaders.erfAqf',
        quotedRate: 'mobile.products.rentalTableHeaders.quotedRate',
        quotedTotal: 'mobile.products.rentalTableHeaders.quotedTotal',
        listRateRowHeader: 'mobile.products.rentalTableHeaders.listRateRowHeader',
        woscoRateRowHeader: 'mobile.products.rentalTableHeaders.woscoRateRowHeader',
        rate: 'mobile.products.rentalTableHeaders.rate',
        percentOfRate: 'mobile.products.rentalTableHeaders.percentOfRate',
        total: 'mobile.products.rentalTableHeaders.total',
        dollarDiff: 'mobile.products.rentalTableHeaders.dollarDiff',
      },
      salesTableHeaders: {
        quantity: 'mobile.products.salesTableHeaders.quantity',
        code: 'mobile.products.salesTableHeaders.code',
        description: 'mobile.products.salesTableHeaders.description',
        priceEach: 'mobile.products.salesTableHeaders.priceEach',
        originalCost: 'mobile.products.salesTableHeaders.originalCost',
        extension: 'mobile.products.salesTableHeaders.extension',
        dollarMargin: 'mobile.products.salesTableHeaders.dollarMargin',
        percentMargin: 'mobile.products.salesTableHeaders.percentMargin',
      },
      serviceTableHeaders: {
        quotedRate: 'mobile.products.serviceTableHeaders.quotedRate',
        listRate: 'mobile.products.serviceTableHeaders.listRate',
        dollarDiff: 'mobile.products.serviceTableHeaders.dollarDiff',
        percentOfRate: 'mobile.products.serviceTableHeaders.percentOfRate',
        oneRulerRate: 'mobile.products.serviceTableHeaders.oneRulerRate',
        percentOfOneRuler: 'mobile.products.serviceTableHeaders.percentOfOneRuler',
        regularHoursSubTotal: 'mobile.products.serviceTableHeaders.regularHoursSubTotal',
        overtimeHoursSubTotal: 'mobile.products.serviceTableHeaders.overtimeHoursSubTotal',
        doubleTimeHoursSubTotal: 'mobile.products.serviceTableHeaders.doubleTimeHoursSubTotal',
        eachSubTotal: 'mobile.products.serviceTableHeaders.eachSubTotal',
        daysSubTotal: 'mobile.products.serviceTableHeaders.daysSubTotal',
        subTotal: 'mobile.products.serviceTableHeaders.subTotal',
      },
    },
    rateLockdown: {
      lockdownRatesRowHeader: 'mobile.rateLockdown.lockdownRatesRowHeader',
      columnHeaders: {
        code: 'mobile.rateLockdown.columnHeaders.code',
      },
    },
    statement: {
      button: 'mobile.statement.button',
      dialogTitle: 'mobile.statement.dialogTitle',
      noStatement: 'mobile.statement.noStatement',
      error: 'mobile.statement.error',
    },
  },
  pdfViewer: {
    viewPdf: 'pdfViewer.viewPdf',
    errorGeneratingQuotePdf: 'pdfViewer.errorGeneratingQuotePdf',
    errorGeneratingChangeOrderPdf: 'pdfViewer.errorGeneratingChangeOrderPdf',
    zoomIn: 'pdfViewer.zoomIn',
    zoomOut: 'pdfViewer.zoomOut',
    rotateLeft: 'pdfViewer.rotateLeft',
    rotateRight: 'pdfViewer.rotateRight',
    save: 'pdfViewer.save',
    pageIndicatorPage: 'pdfViewer.pageIndicatorPage',
    pageIndicatorOf: 'pdfViewer.pageIndicatorOf',
  },
  rental: {
    columnHeaders: {
      code: 'rental.columnHeaders.code',
      description: 'rental.columnHeaders.description',
      unit: 'rental.columnHeaders.unit',
      quantity: 'rental.columnHeaders.quantity',
      dayRate: 'rental.columnHeaders.dayRate',
      weekRate: 'rental.columnHeaders.weekRate',
      cycleRate: 'rental.columnHeaders.cycleRate',
      monthRate: 'rental.columnHeaders.monthRate',
      period: 'rental.columnHeaders.period',
      duration: 'rental.columnHeaders.duration',
      extension: 'rental.columnHeaders.extension',
      percentageOfRate: 'rental.columnHeaders.percentageOfRate',
      rppFee: 'rental.columnHeaders.rppFee',
      erfAqf: 'rental.columnHeaders.erfAqf',
    },
    bulkEdit: {
      quantityTitle: 'rental.bulkEdit.quantityTitle',
      periodTitle: 'rental.bulkEdit.periodTitle',
      periodNotAppliedToSomeRows: 'rental.bulkEdit.periodNotAppliedToSomeRows',
      durationTitle: 'rental.bulkEdit.durationTitle',
      percentOfRateTitle: 'rental.bulkEdit.percentOfRateTitle',
    },
    validationMessages: {
      invalidDescription: 'rental.validationMessages.invalidDescription',
      missingQuantity: 'rental.validationMessages.missingQuantity',
      missingPeriod: 'rental.validationMessages.missingPeriod',
      missingDuration: 'rental.validationMessages.missingDuration',
      dayRateTooLowRelativeToWeekRate: 'rental.validationMessages.dayRateTooLowRelativeToWeekRate',
      dayRateGreaterThanWeekRate: 'rental.validationMessages.dayRateGreaterThanWeekRate',
      weekRateTooLowRelativeToCycleRate: 'rental.validationMessages.weekRateTooLowRelativeToCycleRate',
      weekRateGreaterThanCycleRate: 'rental.validationMessages.weekRateGreaterThanCycleRate',
      dayRateLockdown: 'rental.validationMessages.dayRateLockdown',
      weekRateLockdown: 'rental.validationMessages.weekRateLockdown',
      cycleRateLockdown: 'rental.validationMessages.cycleRateLockdown',
      missingRate: 'rental.validationMessages.missingRate',
    },
    totals: {
      numberOfRentalProducts: 'rental.totals.numberOfRentalProducts',
      quotedTotal: 'rental.totals.quotedTotal',
      listTotal: 'rental.totals.listTotal',
      dollarDiff: 'rental.totals.dollarDiff',
      percentOfRate: 'rental.totals.percentOfRate',
      rppEligibleTotal: 'rental.totals.rppEligibleTotal',
      rppFeeTotal: 'rental.totals.rppFeeTotal',
    },
    woscoRate: 'rental.woscoRate',
  },
  bulkEditButtons: {
    apply: 'bulkEditButtons.apply',
    reset: 'bulkEditButtons.reset',
  },
  sales: {
    columnHeaders: {
      code: 'sales.columnHeaders.code',
      description: 'sales.columnHeaders.description',
      unit: 'sales.columnHeaders.unit',
      quantity: 'sales.columnHeaders.quantity',
      priceEach: 'sales.columnHeaders.priceEach',
      originalCost: 'sales.columnHeaders.originalCost',
      extension: 'sales.columnHeaders.extension',
      dollarMargin: 'sales.columnHeaders.dollarMargin',
      percentMargin: 'sales.columnHeaders.percentMargin',
    },
    bulkEdit: {
      percentMarginTitle: 'sales.bulkEdit.percentMarginTitle',
    },
    totals: {
      salesProductCount: 'sales.totals.salesProductCount',
      quotedTotal: 'sales.totals.quotedTotal',
      priceEachTotal: 'sales.totals.priceEachTotal',
      originalCostTotal: 'sales.totals.originalCostTotal',
      dollarMarginTotal: 'sales.totals.dollarMarginTotal',
      percentMarginTotal: 'sales.totals.percentMarginTotal',
    },
  },
  services: {
    validationMessages: {
      invalidDescription: 'services.validationMessages.invalidDescription',
      missingQuantity: 'services.validationMessages.missingQuantity',
      missingRate: 'services.validationMessages.missingRate',
      missingDriver: 'services.validationMessages.missingDriver',
      missingTruck: 'services.validationMessages.missingTruck',
      missingHours: 'services.validationMessages.missingHours',
      missingRegDuration: 'services.validationMessages.missingRegDuration',
    },
    oneRulerRate: 'services.oneRulerRate',
    bulkEdit: {
      percentOfRateTitle: 'services.bulkEdit.percentOfRateTitle',
    },
  },
  reportsCommon: {
    byQuoteType: {
      QUOTE: {
        quoteNumber: 'reportsCommon.byQuoteType.QUOTE.quoteNumber',
      },
      CHANGE_ORDER: {
        quoteNumber: 'reportsCommon.byQuoteType.CHANGE_ORDER.quoteNumber',
      },
    },
    customer: 'reportsCommon.customer',
    assignedTo: 'reportsCommon.assignedTo',
    prepared: 'reportsCommon.prepared',
  },
  servicesReport: {
    reportTitle: 'servicesReport.reportTitle',
    pageTitle: 'servicesReport.pageTitle',
    servicesSectionTitle: 'servicesReport.servicesSectionTitle',
    optionalServicesSectionTitle: 'servicesReport.optionalServicesSectionTitle',
    packageNamePhaseOnly: 'servicesReport.packageNamePhaseOnly',
    packageNamePhaseAndDescription: 'servicesReport.packageNamePhaseAndDescription',
    columnHeaders: {
      code: 'servicesReport.columnHeaders.code',
      description: 'servicesReport.columnHeaders.description',
      quantity: 'servicesReport.columnHeaders.quantity',
      rateType: 'servicesReport.columnHeaders.rateType',
      regularHours: 'servicesReport.columnHeaders.regularHours',
      overtimeHours: 'servicesReport.columnHeaders.overtimeHours',
      doubleTimeHours: 'servicesReport.columnHeaders.doubleTimeHours',
      regularRate: 'servicesReport.columnHeaders.regularRate',
      extension: 'servicesReport.columnHeaders.extension',
      percentageOfRate: 'servicesReport.columnHeaders.percentageOfRate',
    },
    emptyPackage: 'servicesReport.emptyPackage',
    packageTotals: 'servicesReport.packageTotals',
    combinedTotals: 'servicesReport.combinedTotals',
  },
  partsListReport: {
    reportTitle: 'partsListReport.reportTitle',
    pageTitle: 'partsListReport.pageTitle',
    customerNumber: 'partsListReport.customerNumber',
    customerCityState: 'partsListReport.customerCityState',
    jobsiteInformation: 'partsListReport.jobsiteInformation',
    printedBy: 'partsListReport.printedBy',
    nonOptionalSectionTitle: 'partsListReport.nonOptionalSectionTitle',
    optionalSectionTitle: 'partsListReport.optionalSectionTitle',
    columnHeaders: {
      quantity: 'partsListReport.columnHeaders.quantity',
      code: 'partsListReport.columnHeaders.code',
      description: 'partsListReport.columnHeaders.description',
    },
  },
  productDetailsReport: {
    reportTitle: 'productDetailsReport.reportTitle',
    pageTitle: 'productDetailsReport.pageTitle',
    subTotal: 'productDetailsReport.subTotal',
    grandTotal: 'productDetailsReport.grandTotal',
    margin: 'productDetailsReport.margin',
    rental: 'productDetailsReport.rental',
    optionalRental: 'productDetailsReport.optionalRental',
    sales: 'productDetailsReport.sales',
    optionalSales: 'productDetailsReport.optionalSales',
  },
  login: {
    loginButton: 'login.loginButton',
    contactHelpDesk: 'login.contactHelpDesk',
    errorDialog: {
      title: 'login.errorDialog.title',
      message: 'login.errorDialog.message',
    },
  },
  validation: {
    clientSideErrors: {
      MissingJobsiteName: 'validation.clientSideErrors.MissingJobsiteName',
      MissingMarketSegment: 'validation.clientSideErrors.MissingMarketSegment',
      MissingTaxExempt: 'validation.clientSideErrors.MissingTaxExempt',
      MissingWorkOfImprovement: 'validation.clientSideErrors.MissingWorkOfImprovement',
      MissingPrevailingWage: 'validation.clientSideErrors.MissingPrevailingWage',
      AccountClosed: 'validation.clientSideErrors.AccountClosed',
    },
    fixErrorsPreventingSave: 'validation.fixErrorsPreventingSave',
  },
  quoteChanges: {
    dialogTitle: 'quoteChanges.dialogTitle',
    totalsChanged: 'quoteChanges.totalsChanged',
    viewRowChanges: 'quoteChanges.viewRowChanges',
    rental: 'quoteChanges.rental',
    optionalRental: 'quoteChanges.optionalRental',
    sales: 'quoteChanges.sales',
    optionalSales: 'quoteChanges.optionalSales',
    services: 'quoteChanges.services',
    optionalServices: 'quoteChanges.optionalServices',
    headers: {
      before: 'quoteChanges.headers.before',
      after: 'quoteChanges.headers.after',
      change: 'quoteChanges.headers.change',
    },
    save: 'quoteChanges.save',
    leave: 'quoteChanges.leave',
    close: 'quoteChanges.close',
    updateFscDialog: {
      title: 'quoteChanges.updateFscDialog.title',
      messageWhenOldDefaultMultiplierIsKnown: 'quoteChanges.updateFscDialog.messageWhenOldDefaultMultiplierIsKnown',
      messageWhenOldDefaultMultiplierIsUnknown: 'quoteChanges.updateFscDialog.messageWhenOldDefaultMultiplierIsUnknown',
      messageWhenAccountIsExempt: 'quoteChanges.updateFscDialog.messageWhenAccountIsExempt',
      update: 'quoteChanges.updateFscDialog.update',
      keep: 'quoteChanges.updateFscDialog.keep',
    },
    invalidProductsMessage: 'quoteChanges.invalidProductsMessage',
  },
  discardChangesDialog: {
    title: 'discardChangesDialog.title',
    message: 'discardChangesDialog.message',
  },
  productGridDnD: {
    moveRow: 'productGridDnD.moveRow',
    moveRowNumber: 'productGridDnD.moveRowNumber',
    moveRowNumberWithCode: 'productGridDnD.moveRowNumberWithCode',
    moveRows: 'productGridDnD.moveRows',
  },
  relatedUsers: {
    notAssigned: 'relatedUsers.notAssigned',
    Estimator: {
      label: 'relatedUsers.Estimator.label',
      updateError: 'relatedUsers.Estimator.updateError',
    },
    SupportSpecialist: {
      label: 'relatedUsers.SupportSpecialist.label',
      updateError: 'relatedUsers.SupportSpecialist.updateError',
    },
  },
  help: {
    helpButton: 'help.helpButton',
    branchSelector: 'help.branchSelector',
    contact: 'help.contact',
    jobsite: 'help.jobsite',
    industry: 'help.industry',
    customerQuotes: 'help.customerQuotes',
    projectName: 'help.projectName',
    quoteDeliveryDate: 'help.quoteDeliveryDate',
    quoteCompletionDate: 'help.quoteCompletionDate',
    addProducts: 'help.addProducts',
    productsMenu: 'help.productsMenu',
    gridActionsColumn: 'help.gridActionsColumn',
    salesGridOriginalCost: 'help.salesGridOriginalCost',
    productsSummary: 'help.productsSummary',
    feesAndRatesSummary: 'help.feesAndRatesSummary',
    totalsSummary: 'help.totalsSummary',
    fsc: 'help.fsc',
    sowTemplateRequestButton: 'help.sowTemplateRequestButton',
    reviewFeesAndRatesSummary: 'help.reviewFeesAndRatesSummary',
    reviewTotals: 'help.reviewTotals',
    editSummaryQuote: 'help.editSummaryQuote',
    doNotCloneRatesForCustomersWithMsa: 'help.doNotCloneRatesForCustomersWithMsa',
  },
};
