import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../types';
import { CustomerTabState } from './customer-tab.types';
import { setJobsiteAction, SetJobsitePayload } from './jobsite.action-creators';

export const updateJobsite = (state: CustomerTabState, payload: SetJobsitePayload) => {
  if (payload.touched !== undefined) {
    state.jobsite.status.touched = payload.touched;
  }
  state.jobsite.jobsite = payload.jobsite;
};

export const copyContactToJobsiteAction = createAsyncThunk(
  'quote/customerTab/copyContactToJobsiteAction',
  async (_payload: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const contact = state.quoteScreen.customerTab.contact.active;
    const jobsite = state.quoteScreen.customerTab.jobsite.jobsite;
    thunkAPI.dispatch(
      setJobsiteAction({
        touched: true,
        jobsite: {
          id: '',
          jobName: jobsite.jobName || '',
          siteContact: contact?.name || '',
          poNumber: jobsite.poNumber,
          address: contact?.address || '',
          city: contact?.city || '',
          state: contact?.state || '',
          zipCode: contact?.zipCode || '',
          sitePhoneNumber: contact?.officePhoneNumber || '',
          siteExtension: contact?.officeExtension || '',
          siteMobilePhoneNumber: contact?.mobilePhoneNumber || '',
          marketSector: jobsite.marketSector,
          marketSectorCode: jobsite.marketSectorCode,
        },
      })
    );
  }
);
